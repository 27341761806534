import { Component, ViewEncapsulation } from '@angular/core';
import { linkScss } from './link-scss';

@Component({
  // eslint-disable-next-line @angular-eslint/prefer-standalone
  standalone: false,
  selector: 'bcf-modal-main-container',
  template: '<ng-content></ng-content>',
  styleUrls: [linkScss],
  encapsulation: ViewEncapsulation.None
})
export class ModalMainContainerComponent {}
